<template>
    <div class="p-relative">
        <!-- Тулбар -->
        <div class="top-btns-bar">
            <!-- <div class="transparent-buttons">
                <v-btn small tile text>
                    <v-icon left>far fa-paper-plane</v-icon>
                    Экспорт_списка
                </v-btn>
            </div> -->
            <!--Панель поиска-->
            <SearchPanel
                v-model="search"
                :has-filter="false"
            />
        </div>

        <!--Форма-->
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>
                <v-col cols="12" sm="12" md="12" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="white-panel-items wpi-in-grids ma-0">
                                <div class="wp-row">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Территориальная_единица") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <v-select
                                                v-model="region"
                                                :items="filteredRegions"
                                                item-text="name"
                                                item-value="id"
                                                hide-details
                                                outlined
                                                dense
                                                append-icon="fas fa-chevron-down"
                                                class="autocomplete-chip ac-200 mt-1 mb-1 mr-1"
                                                :placeholder="$t('Территориальная_единица')"
                                                :menu-props="{ bottom: true, offsetY: true }"
                                                clearable
                                                v-tooltip.top-center='getRegionTooltip()'
                                                :readonly="filteredRegions.length == 1"
                                            >
                                                <template v-slot:prepend-item>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-text-field
                                                                v-model="regionSearch"
                                                                :placeholder="$t('Поиск')"
                                                                class="mb-2 autocomplete-search"
                                                                hide-details
                                                                dense
                                                                clearable
                                                                autofocus
                                                            >
                                                            </v-text-field>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div class="wp-row" v-if="region">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Статус") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData status-panel">
                                                <v-chip-group 
                                                    v-model="workStatus"
                                                    mandatory
                                                    column
                                                >
                                                    <v-chip
                                                        v-for="item in workStatusItems"
                                                        :key="item.name"
                                                        :value="item.value"
                                                        :class="[item.value === workStatus ? 'active-panel-item' : '', 'ma-1']"
                                                        small
                                                        label
                                                    >
                                                        {{ $t(item.name) }}
                                                    </v-chip>

                                                </v-chip-group> 
                                            </div>  
                                        </v-col>
                                    </v-row>
                                </div>

                                <div class="wp-row" v-if="region && workStatus != 'Disbanded'">
                                    <v-row class="one-column-field-in-one-box" no-gutters>
                                        <v-col cols="12" sm="12" md="2">
                                            <label class="f-label">{{ $t("Число_избирателей") }}</label>
                                        </v-col>
                                        <v-col  cols="12" sm="12" md="10">
                                            <div class="onlyReadData status-panel">
                                                <v-chip-group 
                                                    v-model="status"
                                                    mandatory
                                                    column
                                                >
                                                    <v-chip
                                                        v-for="item in statusItems"
                                                        :key="item.name"
                                                        :value="item.value"
                                                        :class="[item.value === status ? filterValueClasses[status] ? filterValueClasses[status] : 'active-panel-item' : '', 'ma-1']"
                                                        small
                                                        label
                                                    >
                                                        {{ $t(item.name) }}
                                                    </v-chip>

                                                </v-chip-group> 
                                            </div>  
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <v-data-table
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            :headers="headers"
            :options.sync="options"
            :page.sync="options.page"
            :items="entities"
            :server-items-length="total"
            :footer-props="{ itemsPerPageOptions:[25, 50, 100] }"
            @page-count="paginationPageCount = $event"
            @dblclick:row="(event, data) => $router.push({ name: 'ElectionPart', params: { id: data.item.id } })"
            :loading="loading"
        >

            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.nameRu`]="{ item }">
                <div class="etw-text-container">
                    <div class="etw-text" v-tooltip.top-center="$i18n.locale == 'ru' ? item.nameRu : item.nameKz">
                        {{ $i18n.locale == 'ru' ? item.nameRu : item.nameKz }}
                    </div>
                </div>
            </template>
            <template v-slot:[`item.address`]="{ item }">
                <div class="etw-text-container">
                    <div class="etw-text" v-tooltip.top-center="item.address">
                        {{ item.address }}
                    </div>
                </div>
            </template>
            <template v-slot:[`item.voterCount`]="{ item }">
                <v-chip
                    v-if="item.status === 0"
                    class="table-label"
                    small
                    label
                    dark
                    :color="getStatusColor(item.voterCount)"
                    @click.stop="onVotersCountClick(item)"
                    v-tooltip.top-center="$t('Нажмите_для_перехода_к_избирателям_на_этом_участке')"
                >
                    <v-icon>
                        fas fa-user
                    </v-icon>
                    <div>{{ item.voterCount }}</div>
                </v-chip>
                <v-chip
                    v-else
                    class="table-label"
                    small
                    label
                    :dark="getObjBadStatus(item.status).theme"
                    :color="getObjBadStatus(item.status).color"
                >
                    <div>{{ getObjBadStatus(item.status).text }}</div>
                </v-chip>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import store from '@/store';
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import _ from 'lodash';
import sys from '@/services/system';
import SearchPanel from '@/components/documents/SearchPanel.vue';

export default {
    name: "ElectionParts",
    components: {
        SearchPanel,
    },
    computed: {
        filteredRegions() {
            let result = [];

            if (!this.regions)
                return result;
            if (this.regions.length > 1){
                result.push({ id: Math.floor(this.regions[0].id/1000)*1000, name: i18n.t("Все") });
            }
            result = result.concat(this.regions.map(x => x));

            if (this.regionSearch)
                return result.filter(i => sys.checkSearch(i.name, this.regionSearch));

            return result;
        },
        headers() {
            return [
                { text: i18n.t("№_участка"), value: 'number', width: '110px' },
                { text: i18n.t("Наименование"), value: 'nameRu', width: '250px', sortable: false },
                { text: i18n.t("Адрес"), value: 'address', sortable: false },
                { text: i18n.t("Количество_избирателей"), value: 'voterCount', width: '200px' }
            ];
        },
        options: {
            get: function() {
                return this.$store.getters['saylau/lists/electionParts/getTableOptions'];
            },
            set: function(newValue) {
                this.$store.commit('saylau/lists/electionParts/SET_TABLE_OPTION', newValue);
            }
        },
        region: {
            get: function() {
                return this.$store.getters['saylau/lists/electionParts/getRegion'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/electionParts/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/electionParts/SET_REGION', v);
            }
        },
        status: {
            get: function() {
                return this.$store.getters['saylau/lists/electionParts/getStatus'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/electionParts/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/electionParts/SET_STATUS', v);
            }
        },
        workStatus: {
            get: function() {
                return this.$store.getters['saylau/lists/electionParts/getWorkStatus'];
            },
            set: function(v) {
                this.$store.commit('saylau/lists/electionParts/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/electionParts/SET_WORK_STATUS', v);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['saylau/lists/electionParts/getSearch'];
            },
            set: _.debounce(function(v) {
                this.$store.commit('saylau/lists/electionParts/SET_TABLE_OPTION_PAGE', 1);
                this.$store.commit('saylau/lists/electionParts/SET_SEARCH', v);
            }, 500)
        },
        filter () {
            return {
                ...this.options,
                search: this.search,
                region: this.region,
                status: this.status,
                workStatus: this.workStatus
            }
        },
    },
    data: () => {
        return {
            statusItems: [
                {
                    "name": "Любое",
                    "value": "All"
                },
                {
                    "name": "Менее_2700",
                    "value": "Low"
                },
                {
                    "name": "От_2700_до_3000",
                    "value": "Medium"
                },
                {
                    "name": "Более_3000",
                    "value": "High"
                }
            ],
            workStatusItems: [
                {
                    "name": "Все",
                    "value": "All"
                },
                {
                    "name": "Действующие",
                    "value": "Active"
                },
                {
                    "name": "Расформированные",
                    "value": "Disbanded"
                },
            ],
            filterValueClasses: {
                Low: 'low',
                Medium: 'medium',
                High: 'high',
                All: null
            },

            regionSearch: null,
            regions: null,

            paginationPageCount: 0,
            loading: false,
            total: 0,            
            entities: []
        }
    },
    methods: {
        onVotersCountClick(item) {
            let { number } = item;
            this.$store.commit('saylau/lists/votersInPartList/SET_EXTENDED_FILTER', null);
            this.$store.commit('saylau/lists/votersInPartList/SET_TABLE_OPTION_PAGE', 1);
            this.$store.commit('saylau/lists/votersInPartList/SET_REGION', this.region);
            this.$store.commit('saylau/lists/votersInPartList/SET_ELECTION_PART', number);
            this.$store.commit('saylau/lists/votersInPartList/SET_FILTER_PANEL_STATE', false);
            this.$router.push({ name: 'Voters' });
        },
        getRegionTooltip() {
            if (!this.regions || !this.region)
                return "";

            let region = this.regions.find(r => r.id === this.region);

            if (!region)
                return "";

            return region.name;
        },
        loadElectionParts() {
            this.entities = [];
            this.total = 0;
            this.loading = true;

            httpAPI
                .get(`saylau/election-parts?filter=${this.getTableFilter()}`)
                .then(response =>
                {
                    this.entities = response.data.items;
                    this.total = response.data.count;
                })
                .finally(() => this.loading = false);
        },
        getTableFilter() {
            let { region, page, itemsPerPage, sortBy, sortDesc, search, status, workStatus } = this.filter;

            let orderBy = sortBy.length <= 0 ? null : sortBy[0];
            let descending = sortDesc.length <= 0 ? null : sortDesc[0];            

            let filter = {
                region,
                page,
                itemsPerPage,
                orderBy,
                descending,
                search,
                status,
                workStatus
            };

            return JSON.stringify(filter);
        },
        getStatusColor(count){
            let color = "";
            if(count < 2700) {
                color  = '#43B581';
            }
            else if(count >= 2700 && count <= 3000) {
                color = '#FAA61A';
            }
            else if(count > 3000) {
               color = '#f04747'; 
            }
            return color;
        },
        getObjBadStatus(status) {
            let text = "";
            let color = "";
            let theme = true;

            if (status == -1) {
                text = i18n.t("Удаленный");
                color = "#e0e0e0";
                theme = false; 
            }
            else if (status == 1) {
                text = i18n.t("Закрытый");
                color = "#878686";
            }
            else if (status == 2) {
                text = i18n.t("Расформированный");
                color = "#f04747";
            }

            return {'text': text, 'color': color, 'theme': theme}
        }
    },
    watch: {
        filter: {
            handler () {
                if (this.region) {
                    this.loadElectionParts();
                }
                else {
                    this.entities = [];
                    this.total = 0;
                }
            },
            deep: true,
        }
    },
    mounted() {
        if (this.regions.length == 1)
            this.region = this.regions[0].id;
    },
    async beforeRouteEnter(to, from, next) {
        let regions = await store.dispatch('saylau/references/fetchRegions');
        next(vm => vm.regions = regions);
    },
    async beforeRouteUpdate(to, from, next) {
        this.regions = await store.dispatch('saylau/references/fetchRegions');
        next();
    }
}
</script>
<style scoped>
    .high{
        background: #f04747;
        color: #fff!important;
    }
    .medium {
        background: #FAA61A;
        color: #fff!important;
    }
    .low {
        background: #43B581;
        color: #fff!important;
    }
</style>